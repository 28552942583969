<template>
<v-stepper v-model="activeStep" class="custom-header">
    <!-- Header -->
    <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step :complete="activeStep > 1" step="1">
            <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">01</span>
                <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Personal Information</span>
                    <span class="text--secondary text-xs">Section 1</span>
                </div>
            </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step :complete="activeStep > 2" step="2">
            <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">02</span>
                <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">COVID-19 Screening</span>
                    <span class="text--secondary text-xs">Section 2</span>
                </div>
            </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 3 -->
        <v-stepper-step step="3">
            <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">03</span>
                <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Screening Questionnaire</span>
                    <span class="text--secondary text-xs">Section 3</span>
                </div>
            </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <!-- Header: Step 4 -->
        <v-stepper-step step="4">
            <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">04</span>
                <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">Consent</span>
                    <span class="text--secondary text-xs">Section 4</span>
                </div>
            </div>
        </v-stepper-step>
    </v-stepper-header>

    <!-- Stepper Items -->
    <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="patientType" :schema="patientType" :keep-model-data="true" />
                </div>
            </div>
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="personalInfo" :schema="conditionalConsent" :keep-model-data="true" :key="personalInfoKey" />
                </div>
            </div>
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2" v-show="this.prePopulate.formTarget === `No`">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="emergencyContact" :schema="emergencyContact" :keep-model-data="true" :key="personalInfoKey" />
                </div>
            </div>
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="covidVaccineStatus" :schema="covidVaccineStatus" :keep-model-data="true" :key="personalInfoKey" />
                </div>
            </div>
            <div class="d-flex justify-end">
                <v-btn color="primary" @click="activeStep = 2">
                    Next
                </v-btn>
            </div>
        </v-stepper-content>

        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2">
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="covid19Screening" :schema="covid19Screening" :keep-model-data="true" />
                </div>
            </div>
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2" v-show="this.prePopulate.covid19Vaccine !== `all`">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                    <FormulateForm v-model="prePopulate" name="covid19ScreeningNoVax" :schema="covid19ScreeningNoVax" :keep-model-data="true" />
                </div>
            </div>
            <v-row v-show="this.prePopulate.coivdScreeningContact === 'Yes'|| this.prePopulate.coivdScreeningPostive === 'Yes'|| this.prePopulate.coivdScreeningSymptoms === 'Yes'|| this.prePopulate.coivdScreeningTravel === 'Yes'">
                <v-card-text>
                    <v-card>
                        <p class="tw-text-4xl tw-text-red-600">
                            You responded YES to a screening questions in Section 2.
                        </p>
                        <p class="tw-text-4xl tw-text-red-600">
                            You should NOT receive a flu shot at the pharmacy at this time and should speak with your pharmacist.
                        </p>
                    </v-card>
                </v-card-text>
            </v-row>
            <div class="d-flex justify-space-between">
                <v-btn outlined @click="activeStep = 1">
                    Previous
                </v-btn>
                <v-btn color="primary" @click="activeStep = 3">
                    Next
                </v-btn>
            </div>

        </v-stepper-content>

        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3">
            <v-card class="mb-8" color="secondary" height="200px"></v-card>

            <div class="d-flex justify-space-between">
                <v-btn outlined @click="activeStep = 2">
                    Previous
                </v-btn>
                <v-btn color="primary" @click="activeStep = 4">
                    Next
                </v-btn>
            </div>

        </v-stepper-content>
        <!-- Stepper Content: Step 4 -->
        <v-stepper-content step="4">

            <div class="Signaturecontainer">
                <div class="Signaturecontainer">
                    <v-card v-show="signatureStatus">
                        <VueSignaturePad :key="refreshSignatureStatus" ref="signaturePad" width="100%" height="300px" :options="{ onBegin, onEnd }" />
                        <div>
                            <!-- <v-btn color="primary" @click="save">
                                Save
                            </v-btn> -->
                            <v-btn color="primary" @click="undo">
                                Undo
                            </v-btn>
                            <!-- <v-btn color="primary" @click="hideSignaturePanel">
                                Close Signature
                            </v-btn> -->
                        </div>
                    </v-card>
                </div>

            </div>
            <div class="d-flex justify-space-between">
                <v-btn outlined @click="activeStep = 3">
                    Previous
                </v-btn>
                <!-- <v-btn color="success" dark form-type="fluOha" base-filename="fluOha" :patient-data="prePopulate" @click="sendtoZenyata">
                        Submit Report
                    </v-btn> -->
                <v-row class="row justify-center align-center">
                    <v-card-actions>
                        <v-col>
                            <div class="demo-space-x">

                                <v-btn color="success" dark form-type="fluOha" base-filename="fluOha" :patient-data="prePopulate" @click="sendtoZenyata">
                                    Submit Report
                                </v-btn>

                            </div>
                        </v-col>
                    </v-card-actions>
                </v-row>
            </div>
        </v-stepper-content>
    </v-stepper-items>

    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline text-xs-center">
                Submitting...
            </v-card-title>
            <v-card-text class="text-xs-center">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-stepper>
</template>

<script>
import * as path from 'path'
import {
  personalInfo,
  patientType,
  fluSchema,
  emergencyContact,
  covidVaccineStatus,
  covid19Screening,
  covid19ScreeningNoVax,
} from '../formSchema/fluSchema'

export default {
  name: 'Portal',
  data() {
    return {
      activeStep: 1,
      emergencyContact,
      covid19ScreeningNoVax,
      personalInfoKey: 0,
      fluSchema,
      covid19Screening,
      covidVaccineStatus,
      patientType,
      personalInfo,
      dialog: false,
      pdfRef: '',
      date: '',
      refreshSignatureStatus: 0,
      typing: false,
      ready: false,
      info: [],
      connections: 0,
      img: null,
      signatureStatus: true,
      prePopulate: {},
    }
  },

  mounted() {
    this.currentDate()
  },
  computed: {
    conditionalType() {
      if (this.prePopulate.formTarget !== 'No') {
        const children = this.personalInfo[1].children.filter(
          ({ name }) =>
            name !== 'childWeight' &&
            name !== 'emergencyContactName' &&
            name !== 'emergencyContactPhone' &&
            name !== 'emergencyContactRelationship' &&
            name !== 'emergencyContactEveningPhone',
        )
        const schema = [
          {
            component: 'h3',
            children: 'Personal Information',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]
        return schema
      }
    },
    conditionalConsent() {
      if (this.prePopulate.dob) {
        const calculateAge = birthday => {
          const ageDifMs = Date.now() - new Date(birthday).getTime()
          const ageDate = new Date(ageDifMs)
          return Math.abs(ageDate.getUTCFullYear() - 1970)
        }
        this.prePopulate.age = calculateAge(this.prePopulate.dob)
        // this.personalInfoKey += 1
        if (this.prePopulate.age < 13) {
          return personalInfo
        } else {
          const children = this.personalInfo[1].children.filter(({ name }) => name !== 'childWeight')
          const schema = [
            {
              component: 'h3',
              children: 'Personal Information',
              class: 'tw-text-2xl tw-mb-4',
            },
            {
              children,
              class: 'tw-grid tw-grid-cols-6 tw-gap-6',
              component: 'div',
            },
          ]
          return schema
        }
      } else {
        const children = this.personalInfo[1].children.filter(({ name }) => name !== 'childWeight')
        const schema = [
          {
            component: 'h3',
            children: 'Personal Information',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]
        return schema
      }
    },
  },

  methods: {
    currentDate() {
      // const current = new Date()
      // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      const date = new Date().toISOString().substr(0, 10)
      return date
    },
    sendtoZenyata() {
      this.dialog = true
      var axios = require('axios')
      var data = JSON.stringify({
        ...this.prePopulate,
        FORM_CLASS: 'fluOha',
        filename: 'fluOha',
      })

      var config = {
        method: 'post',
        url: 'https://pdfdev.pharmaguide.ca/process.php',
        // url: 'http://localhost/process.php',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          const pdfRef = path.basename(response.data.pdfUrl).split('.').slice(0, -1).join('.')
          return pdfRef
        })
        .then(async pdfRef => {
          var axios = require('axios')
          var accessToken = await this.$auth.getTokenSilently()
          await axios
            .get(`https://apidev.pharmaguide.ca/pdf/${pdfRef}`, {
              responseType: 'arraybuffer',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/pdf',
              },
            })
            .then(resp => resp.data)

            .then(blobData => {
              fileDownload(blobData, `fluOha`.toLowerCase(), 'application/octet-stream')
              this.dialog = false
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getPdf(pdfRef) {
      const accessToken = await this.$auth.getTokenSilently()
      var axios = require('axios')
      var config = {
        method: 'get',
        url: `https://apidev.pharmaguide.ca/pdf/${pdfRef}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios(config).then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      // .then(blobData => {
      //   fileDownload(blobData, `cardioReport-${moment().format('YYYY-MM-DD')}`.toLowerCase())
      // })
    },

    onBegin() {
      console.log('=== Begin ===')
    },
    onEnd() {
      if ((this.refreshSignatureStatus = 1)) {
        this.refreshSignatureStatus += 1
      }

      console.log('=== End ===')
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
    },
    hideSignaturePanel() {
      this.signatureStatus = false
    },
  },
}

function fileDownload(data, filename, mime) {
  var blob = new Blob([data], {
    type: mime || 'application/octet-stream',
  })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, `${filename}.pdf`)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `${filename}.pdf`)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
</script>

<style lang="scss" scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
</style>
