export const patientType = [
  {
    component: 'h3',
    children: 'Form Type',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    type: 'radio',
    label: 'Are you filling this form for yourself or someone else?',
    options: {
      Yes: 'Myself',
      No: 'Someone else'
    },
    name: 'formTarget',
    key: 'formTarget',
  }
]
export const personalInfo = [
  {
    component: 'h3',
    children: 'Personal Information',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [

      {
        type: 'text',
        label: 'Patient First Name',
        name: 'firstName',
        key: 'firstName'
      },
      {
        type: 'text',
        label: 'Patient Last Name',
        name: 'lastName',
        key: 'lastName'
      },
      {
        type: 'datepicker',
        label: 'Date of Birth',
        name: 'dob',
        key: 'dob'
      },
      {
        type: 'text',
        label: 'Patient Address:',
        name: 'ptAddress',
        key: 'ptAddress'
      },
      {
        type: 'text',
        label: 'Patient Telephone:',
        name: 'ptTel',
        key: 'ptTel'
      },

      {
        type: 'text',
        label: 'Patient OHIP No. (if applicable):',
        name: 'ohip',
        key: 'ohip'
      },
      // {
      //   type: 'text',
      //   label: 'Age:',
      //   name: 'age',
      //   key: 'age'
      // },
      {
        type: 'radio',
        label: 'Gender',
        options: {
          F: 'Female',
          M: 'Male'
        },
        name: 'gender',
        key: 'gender'
      },
      {
        type: 'text',
        label: 'Child’s Weight: kg OR lb',
        name: 'childWeight',
        key: 'childWeight'
      },



    ],
  },
]
export const emergencyContact = [
  {
    component: 'h3',
    children: 'Emergency Contact Information',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Name of Emergency Contact:',
        name: 'emergencyContactName',
        key: 'emergencyContactName'
      },
      {
        type: 'text',
        label: 'Contact’s Daytime Phone Number:',
        name: 'emergencyContactPhone',
        key: 'emergencyContactPhone'
      },
      {
        type: 'text',
        label: 'Emergency Contact’s Relationship to Patient:',
        name: 'emergencyContactRelationship',
        key: 'emergencyContactRelationship'
      },
      {
        type: 'text',
        label: 'Contact’s Evening/Other Phone Number:',
        name: 'emergencyContactEveningPhone',
        key: 'emergencyContactEveningPhone'
      },
    ],
  },
]
export const covidVaccineStatus = [
  {
    component: 'h3',
    children: 'COVID-19 Vaccination Status',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'radio',
        label: `Please select Patient's COVID- 19 Vaccination Status`,
        options: {
          all: 'Received all required doses > 14 days ago',
          no: 'Unimmunized/partially immunized/< 14 days after final Dose',
          undisclosed: 'Undisclosed'
        },
        name: 'covid19Vaccine',
        key: 'covid19Vaccine'
      },
    ],
  },
]
export const covid19Screening = [
  {
    component: 'h3',
    children: 'COVID-19 Screening',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'p',
    children: 'Are you experiencing any of the following symptoms? (If you received a COVID-19 vaccination in the last 48 hours and are experiencing mild fatigue, muscle aches and/or joint pain that only began after vaccination, select “No.”) '
  },
  {
    component: 'p',
    children: '•	Fever and/or chills'
  },
  {
    component: 'p',
    children: '•	New onset of cough or worsening chronic cough'
  },
  {
    component: 'p',
    children: '•	Decrease or loss of smell or taste'
  },
  {
    component: 'p',
    children: '•	Fatigue, lethargy, malaise and/or muscle aches (myalgias) (for adults ≥18 years of age)'
  },
  {
    component: 'p',
    children: '•	Nausea, vomiting and/or diarrhea (for children <18 years of age)'
  },
  {
    component: 'div',
    // class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'radio',
        // label: 'Are you experiencing any of the following symptoms?',
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        name: 'coivdScreeningSymptoms',
        key: 'coivdScreeningSymptoms'
      },
      {
        type: 'radio',
        label: 'In the last 10 days, have you tested positive for COVID-19 or have been told that you should be isolating?',
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        name: 'coivdScreeningPostive',
        key: 'coivdScreeningPostive'
      },
    ],
  },
]
export const covid19ScreeningNoVax = [
  {
    component: 'h3',
    children: 'If you are not fully vaccinated*, please also answer the following two questions.',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'radio',
        label: 'Did you travel outside of Canada in the past 14 days?',
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        name: 'coivdScreeningTravel',
        key: 'coivdScreeningTravel'
      },

      {
        type: 'radio',
        label: 'Have you had close contact with a confirmed case of COVID-19 without wearing appropriate PPE?',
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        name: 'coivdScreeningContact',
        key: 'coivdScreeningContact'
      },
    ],
  },
]
export const fluSchema = [
  {
    component: 'h3',
    children: 'Additional Screening Questions for Live Vaccines: (Flu Mist)',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [


      {
        type: 'text',
        label: 'If YES, list what you are allergic to here:',
        name: 'allergistext',
        key: 'allergistext'
      },
      {
        type: 'text',
        label: 'Patient/Agent Name (& Relationship)',
        name: 'patientAgentNameRel',
        key: 'patientAgentNameRel'
      },
      {
        type: 'text',
        label: 'Patient/Agent Signature',
        name: 'PatientAgent_Signature',
        key: 'PatientAgent_Signature'
      },
      {
        type: 'text',
        label: 'Date Signed (MM/DD/YYYY)',
        name: 'dateSignedPt',
        key: 'dateSignedPt'
      },
      {
        type: 'text',
        label: 'Pharmacist Signature',
        name: 'Pharmacist_Signature',
        key: 'Pharmacist_Signature'
      },
      {
        type: 'text',
        label: 'undefined',
        name: 'ocp',
        key: 'ocp'
      },
      {
        type: 'text',
        label: 'Date Signed (MM/DD/YYYY)',
        name: 'dateSignedRph',
        key: 'dateSignedRph'
      },
      {
        type: 'text',
        label: 'Expiry (MM/YYYY):',
        name: 'expGiven',
        key: 'expGiven'
      },
      {
        type: 'text',
        label: 'Number of Doses Administered:',
        name: 'Number_of_Doses_Administered',
        key: 'Number_of_Doses_Administered'
      },
      {
        type: 'text',
        label: 'Date of Immunization:',
        name: 'dateAdmin',
        key: 'dateAdmin'
      },
      {
        type: 'text',
        label: 'Date of Administration:',
        name: 'dateOfAdminEmergency',
        key: 'dateOfAdminEmergency'
      },
      {
        type: 'text',
        label: 'Dose mL',
        name: 'dose',
        key: 'dose'
      },
      {
        type: 'text',
        label: 'Administering Pharmacist Signature:',
        name: 'Administering_Pharmacist_Signature',
        key: 'Administering_Pharmacist_Signature'
      },
      {
        type: 'text',
        label: 'Additional Notes (including other emergency measures taken or treatments administered):',
        name: 'emergencyNotes',
        key: 'emergencyNotes'
      },
      {
        type: 'text',
        label: 'Administering Pharmacist Signature:',
        name: 'Administering_Pharmacist_Signature_2',
        key: 'Administering_Pharmacist_Signature_2'
      },
      {
        type: 'text',
        label: 'Date & Time of Follow-up with Patient/Agent:',
        name: 'dateTimeFollowup',
        key: 'dateTimeFollowup'
      },
      {
        type: 'text',
        label: 'Lot number:',
        name: 'lotGiven',
        key: 'lotGiven'
      },

      {
        type: 'text',
        label: 'undefined',
        name: 'pharmacistName',
        key: 'pharmacistName'
      },


      {
        type: 'text',
        label: 'Time of Immunization:',
        name: 'timeAdmin',
        key: 'timeAdmin'
      },
      {
        type: 'text',
        label: 'Other: Location of Immunization',
        name: 'otherLocation',
        key: 'otherLocation'
      },
      {
        type: 'text',
        label: 'Time Of Administration 1:',
        name: 'TimeOfAdminEmergency1',
        key: 'TimeOfAdminEmergency1'
      },
      {
        type: 'text',
        label: 'Time Of Administration 2',
        name: 'TimeOfAdminEmergency2',
        key: 'TimeOfAdminEmergency2'
      },
      {
        type: 'text',
        label: 'Time Of Administration 3',
        name: 'TimeOfAdminEmergency3',
        key: 'TimeOfAdminEmergency3'
      },
      {
        type: 'checkbox',
        label: 'Thimerosal',
        options: {
          thimerosal: 'thimerosal'
        },
        name: 'thimerosal',
        key: 'thimerosal'
      },
      {
        type: 'checkbox',
        label: 'Egg/egg protein/chicken protein',
        options: {
          egg: 'egg'
        },
        name: 'egg',
        key: 'egg'
      },
      {
        type: 'checkbox',
        label: 'Kanamycin, neomycin, polymyxin B',
        options: {
          kanamycin: 'kanamycin'
        },
        name: 'kanamycin',
        key: 'kanamycin'
      },
      {
        type: 'checkbox',
        label: 'Formaldehyde',
        options: {
          formaldehyde: 'formaldehyde'
        },
        name: 'formaldehyde',
        key: 'formaldehyde'
      },
      {
        type: 'checkbox',
        label: 'Sodium Deoxycholate',
        options: {
          sodiumDeoxycholate: 'sodiumDeoxycholate'
        },
        name: 'sodiumDeoxycholate',
        key: 'sodiumDeoxycholate'
      },
      {
        type: 'checkbox',
        label: 'Triton® X-100',
        options: {
          triton: 'triton'
        },
        name: 'triton',
        key: 'triton'
      },
      {
        type: 'checkbox',
        label: 'Hydrocortisone',
        options: {
          hydrocortisone: 'hydrocortisone'
        },
        name: 'hydrocortisone',
        key: 'hydrocortisone'
      },
      {
        type: 'checkbox',
        label: 'Cetyltrimethylammonium bromide (CTAB',
        options: {
          cetyltrimethylammonium: 'cetyltrimethylammonium'
        },
        name: 'cetyltrimethylammonium',
        key: 'cetyltrimethylammonium'
      },
      {
        type: 'checkbox',
        label: 'Polysorbate 80',
        options: {
          polysorbate: 'polysorbate'
        },
        name: 'polysorbate',
        key: 'polysorbate'
      },
      {
        type: 'radio',
        label: 'Are you sick today? (fever greater than 39.5, breathing problems, or active infection)',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'sickToday',
        key: 'sickToday'
      },

      {
        type: 'radio',
        label: 'Do you have any allergies that you are aware of?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'allergiesScreening',
        key: 'allergiesScreening'
      },

      {
        type: 'radio',
        label: 'Are you allergic to any of the following?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'allergicIngredient',
        key: 'allergicIngredient'
      },

      {
        type: 'radio',
        label: 'Are you allergic to any part of the flu shot, or have you had a severe, life-threatening allergic reaction to a past flu shot?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'severeAllergicReaction',
        key: 'severeAllergicReaction'
      },

      {
        type: 'radio',
        label: 'Have you had wheezing, chest tightness or difficulty breathing within 24 hours of getting a flu shot?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'wheezing',
        key: 'wheezing'
      },

      {
        type: 'radio',
        label: 'Do you have any serious allergy to latex or natural rubber?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'latexAllergy',
        key: 'latexAllergy'
      },

      {
        type: 'radio',
        label: 'Have you had Guillain-Barre Syndrome within 6 weeks of getting a flu shot?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'guillainBarre',
        key: 'guillainBarre'
      },

      {
        type: 'radio',
        label: 'Do you have a new or changing neurlogical disorder?',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'newNeurologicalDisorder',
        key: 'newNeurologicalDisorder'
      },

      {
        type: 'radio',
        label: 'Do you have bleeding problems or use blood thinners? (e.g., warfarin, low dose or regular strength aspirin',
        options: {
          Yes: 'Yes',
          No: 'No',
          unsure: 'unsure'
        },
        name: 'bleeding',
        key: 'bleeding'
      },

      {
        type: 'radio',
        label: 'Site of Deltoid Administration',
        options: {
          right: 'right',
          left: 'left'
        },
        name: 'siteDeltoid',
        key: 'siteDeltoid'
      },

      {
        type: 'radio',
        label: 'Location of Immunization:',
        options: {
          pharmacy: 'pharmacy',
          other: 'other'
        },
        name: 'locatoinOfAdmin',
        key: 'locatoinOfAdmin'
      },

      {
        type: 'radio',
        label: 'Epinephrine Emergency Treatment',
        options: {
          epipen: 'Epipen',
          epipenjr: 'Epipen Jr',
          allerject: 'Allerject',
          allerjectjr: 'Allerject Jr',
          emerade: 'Emerade',
          emeradejr: 'Emerade Jr'
        },
        name: 'epinephrineEmergencyTreatment',
        key: 'epinephrineEmergencyTreatment'
      },

      {
        type: 'radio',
        label: 'Please Select Influenza Vaccine Administered',
        options: {
          flulaval: 'Flulaval Tetra',
          fluzonemd: 'Fluzone Quadrivalent Multi-Dose',
          fluzonesd: 'Fluzone Quadrivalent Single-Dose',
          afluriamd: 'Afluria Tetra Multi-Dose',
          afluriasd: 'Afluria Tetra Single-Dose',
          flucelvax: 'Flucelvax Quad Single-Dose',
          fluzonehd: 'Fluzone High-Dose Quadrivalent',
          fluad: 'Fluad TIV'
        },
        name: 'influenzaVaccine',
        key: 'influenzaVaccine'
      },

      {
        type: 'radio',
        label: 'Consent',
        options: {
          Yes: 'I confirm that I want to receive the seasonal influenza Vaccine',
          Yeschild: 'I confirm that I want my child 2 years of age or oder to receive the seasonal influenza vaccine'
        },
        name: 'consent',
        key: 'consent'
      }
    ],
  },
]